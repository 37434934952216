<template>
  <p id="form-submission-error" class="p-2 font-light bg-red-100 text-red-700 rounded">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'ErrorTextContainer'
}
</script>
